.wrapper {
  row-gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .digitWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    border-radius: 3px;
    background: var(--simetria-p10);

    &.urgent {
      background: var(--simetria-red-20);
    }

    &.disabled {
      background: var(--simetria-gray-20);
    }

    .digit {
      color: var(--simetria-blue-60);

      &.urgent {
        color: var(--simetria-red-60);
      }

      &.disabled {
        color: var(--simetria-gray-30);
      }
    }

    .timeType {
      color: var(--simetria-gray-60);

      &.disabled {
        color: var(--simetria-gray-30);
      }
    }
  }
}
