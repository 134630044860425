.rowWrapper {
    padding-bottom: 23px;

    .dateWrapper {
        display: flex;
        align-items: center;
        gap: 6px;

        padding-top: 16px;

        .dateValue {
            color: var(--simetria-text-main);

            &.disabledText {
                color: var(--simetria-text-disabled);
            }
        }
    }
}