.signInWrapper {
  display: grid;
  grid-template-rows: 2fr repeat(3, 1fr);
  gap: 0px 0px;
  grid-template-areas:
    "."
    "logo"
    "button"
    "footer";
  justify-items: center;

  width: 100vw;
  height: 100vh;
  background-color: var(--simetria-p50);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: var(--signin-city-bg);

  .logo {
    grid-area: logo;
  }

  .signInButton {
    width: 400px;
    justify-content: center;
    grid-area: button;
  }

  .infoText {
    grid-area: text;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--simetria-white);
  }

  .footer {
    grid-area: footer;
    align-self: flex-end;

    padding-bottom: 36px;
  }
}