.noDatesHeaderWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    span:last-child {
        color: var(--simetria-text-secondary)
    }
}