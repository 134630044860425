.centeredContent {
  text-align: center;
}

.footerWrapper {
  .buttonsWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;

    &.smallerButtons {
      & > button {
        margin: 0;
        width: 350px;
      }
    }

    &.largerButtons {
      & > button {
        margin: 0;
        width: 448px;
      }
    }

    & > button {
      &.alertColor {
        background-color: var(--simetria-button-danger);
      }
    }

    span.buttonLabel {
      width: 100%;
      text-align: center;
    }
  }
}
