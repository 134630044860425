.wrapper {
  inset: 0;

  .container {
    text-align: center;

    .imgWrap {
      min-height: 600px;
    }

    h1 {
      font-size: 3rem;
    }
  }
}
