.poweredBySimetriaWrapper {
    flex: 1 1;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    .contentWrapper {
        display: flex;
        align-items: center;
        gap: 6px;

        height: 64px;
        padding-block: 18px;

        .poweredByText {
            color: var(--simetria-gray-30);
        }
    }
}