.badgeWrapper {
  height: 28px;
  padding: 4px 8px;
  width: fit-content;
  border-radius: var(--border-radius);

  display: flex;
  align-items: center;
  justify-content: center;

  &.rightIcon {
    flex-direction: row-reverse;
  }

  &.small {
    height: 24px;
  }

  &.medium {
    height: 26px;
  }

  &.auto {
    height: auto;
  }

  &.full {
    width: 100% !important;
  }

  .iconWrapper {
    padding-inline: 4px;

    &.warning>svg>path {
      fill: var(--simetria-orange-60);
    }

    &.base>svg>path {
      fill: var(--simetria-text-secondary);
    }

    &.neutral>svg>path {
      fill: var(--simetria-blue-60);
    }

    &.positive>svg>path {
      fill: var(--simetria-green-60);
    }

    &.negative>svg>path {
      fill: var(--simetria-red-60);
    }

    &.disabled>svg>path {
      fill: var(--simetria-text-disabled);
    }
  }

  &.badgeWarning {
    color: var(--simetria-orange-60);
    background-color: var(--simetria-orange-20);
  }

  &.badgeDisabled {
    color: var(--simetria-text-disabled);
    background-color: var(--simetria-gray-20);
  }

  &.badgeNeutral {
    color: var(--simetria-blue-60);
    background-color: var(--simetria-p20);
  }

  &.badgePositive {
    color: var(--simetria-green-60);
    background-color: var(--simetria-green-20);
  }

  &.badgeNegative {
    color: var(--simetria-red-60);
    background-color: var(--simetria-red-20);
  }

  &.badgeBase {
    color: var(--simetria-text-secondary);
    background-color: var(--simetria-gray-20);
  }
}