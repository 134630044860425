.itemWrapper {
    display: flex;
    align-items: center;

    height: 36px;

    .avatarWrapper {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 50%;
        background: var(--simetria-gray-30);
    }

    .optionLabel {
        color: var(--simetria-button-teriatery);
    }
}