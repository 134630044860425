.noCoincidenceBox {
    width: 100%;
    font-size: 14px;
    text-align: left;
    padding: 8px 12px;
    border-radius: var(--border-radius);
    color: var(--simetria-text-secondary);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    .blueColor {
        color: var(--simetria-button-normal);
    }
}