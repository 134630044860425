.timerWrapper {
    position: relative;

    height: 100px;
    min-width: 260px;
    border-radius: 3px;

    .timer {
        position: absolute;
        left: 0;

        width: 100%;
        z-index: 10;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid var(--simetria-border-blue)
    }
}