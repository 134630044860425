.typography {
  &.h1 {
    font-size: 28px;
    line-height: 42px;
    font-family: "PoppinsSemiBold";
  }

  &.h2 {
    font-size: 32px;
    line-height: 48px;
    font-family: "PoppinsBold";
  }

  &.h3 {
    font-size: 28px;
    line-height: 42px;
    font-family: "PoppinsBold";
  }

  &.h4 {
    font-size: 22px;
    line-height: 30px;
    font-family: "PoppinsBold";
  }

  &.h5 {
    font-size: 42px;
    line-height: 63px;
    font-family: "PoppinsRegular";
  }

  &.h6 {
    font-size: 24px;
    line-height: 33px;
    font-family: "PoppinsRegular";
  }

  &.s1 {
    font-size: 18px;
    line-height: 27px;
    font-family: "PoppinsMedium";
  }

  &.s2 {
    font-size: 18px;
    line-height: 27px;
    font-family: "PoppinsMediumItalic";
  }

  &.s3 {
    font-size: 18px;
    line-height: 27px;
    font-family: "PoppinsLight";
  }

  &.s4 {
    font-size: 16px;
    line-height: 24px;
    font-family: "PoppinsMedium";
  }

  &.s5 {
    font-size: 16px;
    line-height: 24px;
    font-family: "PoppinsLight";
  }

  &.b1 {
    font-size: 18px;
    line-height: 27px;
    font-family: "PoppinsSemiBold";
  }

  &.b2 {
    font-size: 18px;
    line-height: 27px;
    font-family: "PoppinsRegular";
  }

  &.b3 {
    font-size: 16px;
    line-height: 24px;
    font-family: "PoppinsSemiBold";
  }

  &.b4 {
    font-size: 16px;
    line-height: 24px;
    font-family: "PoppinsRegular";
  }

  &.b5 {
    font-size: 14px;
    line-height: 21px;
    font-family: "PoppinsMedium";
  }

  &.b6 {
    font-size: 14px;
    line-height: 21px;
    font-family: "PoppinsRegular";
  }

  &.b7 {
    font-size: 12px;
    line-height: 18px;
    font-family: "PoppinsMedium";
  }

  &.b8 {
    font-size: 12px;
    line-height: 18px;
    font-family: "PoppinsRegular";
  }

  &.caption {
    font-size: 12px;
    line-height: 18px;
    font-family: "PoppinsSemiBold";
  }

  &.overline {
    font-size: 12px;
    line-height: 18px;
    text-decoration: overline;
    font-family: "PoppinsMedium";
  }

  &.underline1 {
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    font-family: "PoppinsRegular";
  }

  &.underline2 {
    font-size: 12px;
    line-height: 18px;
    text-decoration: underline;
    font-family: "PoppinsRegular";
  }

  &.block {
    display: block;
  }

  &.inline-block {
    display: inline-block;
  }

  &.inline {
    display: inline;
  }
}