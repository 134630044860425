.itemWrapper {
    display: flex;
    align-items: center;

    height: 36px;
  
    .companyAvatar {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
      background: var(--simetria-gray-30);
    }
  
    .companyItemBox {
      font-size: 14px;
      color: var(--simetria-text-secondary);
  
      .companyName {
        font-family: "PoppinsMedium";
      }
    }
  }