.contentWrapper {
  min-height: 70vh;

  .documentDisplay {
    position: relative;

    width: 100%;
    min-height: 100vh;

    .documentToSign {
      position: absolute;
      inset: 0;

      width: 100%;
      height: 100%;

      font-family: "PoppinsItalic";
    }
  }
}
