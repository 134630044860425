// "Poppins" regular

// font-family: "PoppinsThin"       = font-weight: 100
// font-family: "PoppinsExtraLight" = font-weight: 200
// font-family: "PoppinsLight"      = font-weight: 300
// font-family: "PoppinsRegular"    = font-weight: 400
// font-family: "PoppinsMedium"     = font-weight: 500
// font-family: "PoppinsSemiBold"   = font-weight: 600
// font-family: "PoppinsBold"       = font-weight: 700
// font-family: "PoppinsExtraBold"  = font-weight: 800
// font-family: "PoppinsBlack"      = font-weight: 900

@font-face {
  font-family: "PoppinsThin"; // 100
  src: local("PoppinsThin"), url("./Poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsExtraLight"; //200
  src: local("PoppinsExtraLight"), url("./Poppins/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsLight"; // 300
  src: local("PoppinsLight"), url("./Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsRegular"; // 400
  src: local("Poppins-Regular"), url("./Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsMedium"; // 500
  src: local("PoppinsMedium"), url("./Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsSemiBold"; //600
  src: local("PoppinsSemiBold"), url("./Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBold"; // 700
  src: local("PoppinsBold"), url("./Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsExtraBold"; // 800
  src: local("PoppinsExtraBold"), url("./Poppins/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBlack"; // 900
  src: local("PoppinsBlack"), url("./Poppins/Poppins-Black.ttf") format("truetype");
}

// "Poppins" italic [same as regular but with font-style: "italic"]

// font-family: "PoppinsThinItalic"
// font-family: "PoppinsExtraLightItalic"
// font-family: "PoppinsLightItalic"
// font-family: "PoppinsItalic"
// font-family: "PoppinsMediumItalic"
// font-family: "PoppinsSemiBoldItalic"
// font-family: "PoppinsBoldItalic"
// font-family: "PoppinsExtraBoldItalic"
// font-family: "PoppinsBlackItalic"

@font-face {
  font-family: "PoppinsThinItalic";
  src: local("PoppinsThinItalic"), url("./Poppins/Poppins-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsExtraLightItalic";
  src: local("PoppinsExtraLightItalic"),
    url("./Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsLightItalic";
  src: local("PoppinsLightItalic"), url("./Poppins/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsItalic";
  src: local("PoppinsItalic"), url("./Poppins/Poppins-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsMediumItalic";
  src: local("PoppinsMediumItalic"), url("./Poppins/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsSemiBoldItalic";
  src: local("PoppinsSemiBoldItalic"),
    url("./Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBoldItalic";
  src: local("PoppinsBoldItalic"), url("./Poppins/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsExtraBoldItalic";
  src: local("PoppinsExtraBoldItalic"),
    url("./Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBlackItalic";
  src: local("PoppinsBlackItalic"), url("./Poppins/Poppins-BlackItalic.ttf") format("truetype");
}
