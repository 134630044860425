.menuItem {
    cursor: pointer;

    padding-inline: 6px;

    &.activeElement {
        padding-inline: 6px;
        padding-bottom: 12px;
        border-bottom: 2px solid var(--simetria-p20)
    }
}