.signInComponent {
  position: absolute;
  inset: 0;

  display: grid;
  place-items: center;

  text-align: center;

  & > div {
    .btnsWrap {
      margin-top: 4rem;
    }
  }
}
