.notUpcomingDateWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .iconWrapper {
        padding: 12px;
        border-radius: 3px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }
}