    .itemsList {
        margin-top: 8px;
        list-style-type: none;

        .listItem {
            display: flex;
            align-items: center;

            height: 48px;
            cursor: pointer;
            user-select: none;
            margin-inline: 20px;

            .itemIcon {
                width: 20px;
                height: 20px;
                margin-inline: 10px;

            }

            &:hover {
                background: var(--simetria-p10);
            }

            &.selected {
                background: var(--simetria-p10);
            }

            &.disabledItem {
                cursor: default;
                color: var(--simetria-text-secondary);

                &:hover {
                    background: transparent
                }
            }
        }
    }