.detailItem {
    cursor: pointer;

    display: flex;
    align-items: center;

    height: 36px;
    font-size: 14px;

    .detailMenuIcon {
        width: 20px;
        height: 20px;
        margin: 8px 10px;
    }

    &:hover {
        background: var(--simetria-p10);
    }
}