.googleBtn {
  justify-content: center;

  font-size: 14px !important;
  background: none !important;
  margin-bottom: 4px !important;
  color: var(--simetria-text-main) !important;
  border: 2px solid var(--simetria-border-blue) !important;

  .googleIcon {
    margin-right: 1rem;
  }
}
