   .headerWrapper {
       display: flex;
       align-items: center;
       justify-content: space-between;

       user-select: none;
       margin-left: 24px;
       margin-right: 16px;
       padding-block: 14px;
       border-bottom: 1px solid var(--simetria-border-default);

       .avatarWrapper {
           padding-right: 8px;
       }

       .left {
           display: flex;
           align-items: center;

           cursor: pointer;

           .headerLabel {
               width: 150px;
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
           }
       }

       &.headerExpanded {
           border-bottom: 1px solid var(--simetria-gray-100);
       }

       .headerTitle {
           font-size: 14px;
           color: var(--simetria-text-main);
       }

       .triggerBtn {
           min-width: 22px;
           padding-right: 6px;
       }
   }