.dialog {
  width: 400px;
  border: 1px solid var(--simetria-border-red);
}

.header {
  display: flex;
  justify-content: center;

  text-align: center;
}

.dialogContent {
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  justify-content: center;

  text-align: center;

  .instructions {
    display: flex;
    align-items: baseline;
    justify-content: center;

    white-space: pre;

    .contactSupportButton {
      font-size: 16px;
    }
  }
}
