       .avatarWrapper {
           width: 24px;
           height: 24px;
           border-radius: 50%;
           background-color: var(--simetria-gray-50);

           .avatarShape {
               width: 100%;
               height: 100%;
           }
       }