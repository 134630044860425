.upcomingDateHeaderWrapper {
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 8px;

    width: 100%;

    .daysLeftWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        width: 60px;
        height: 60px;
        padding: 6px;
        border-radius: 3px;
        color: var(--simetria-white);
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

        span:first-child {
            padding-top: 4px;
            line-height: 28px;
        }

        &.urgentSale {
            background-color: var(--simetria-red-40)
        }

        &.regularSale {
            background-color: var(--simetria-purple-blue)
        }
    }

    .contentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        span:first-child {
            color: var(--simetria-text-secondary);
        }

        span:last-child {
            &:not(.redText) {
                color: var(--simetria-purple-blue);
            }

            &.redText {
                color: var(--simetria-red-60);
            }
        }
    }
}