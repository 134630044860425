.tooltipContent {
  * {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    font-style: normal;
    font-family: "PoppinsRegular";
    max-width: 600px;
  }
}
