.sideBarWrapper {
  overflow: hidden;
  padding-right: 16px;
  height: calc(100vh - 74px);

  &:hover {
    overflow-y: scroll;
    padding-right: 2px;
    height: calc(100vh - 88px);
  }

  .sideBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 240px;
    padding-top: 32px;
    min-height: calc(100vh - 74px);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    .itemsWrapper {
      padding-bottom: 86px;
    }

    .addCompanyWrapper {
      position: fixed;
      bottom: 30px;

      margin-left: 30px;

      button {
        background-color: transparent !important;

        .addDealIcon {
          width: 44px;
          height: 44px;

          path {
            fill: var(--simetria-button-normal);
          }
        }
      }
    }
  }
}