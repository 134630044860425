.wrapper {
  display: grid;
  place-items: center;

  height: 100%;

  .container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    .title {
      margin-top: 46px;
    }

    .description {
      text-align: center;
      margin: 20px 0 72px 0;
    }
  }
}
