.wrapper {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
  justify-content: center;

  width: 290px;
  height: 110px;

  border-radius: 3px;
  padding: 12px 30px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  .nameAndYMD {
    display: flex;
    justify-content: space-between;

    .saleDateName {
      color: var(--simetria-gray-60);
    }
  }

  .time {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;

    column-gap: 10px;
  }
}
